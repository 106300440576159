<template>
  <div>
    <div class="h-text-center">
      <div class="h-m-t-10">
        <van-image :src="require('@/assets/images/pay_success.png')" style="height:120px;width:120px" />
      </div>
      <div class="h-font-lg h-font-bold-sm h-m-t-10">
        <span class="h-primary-color">恭喜您！</span>
        <span>支付成功！</span>
      </div>
      <div class="h-font-md h-text-secondary-color h-m-t-5">订单号：{{ orderNo }}</div>
    </div>
    <div class="btn">
      <van-button type="primary" block @click="toContractDetail">查看</van-button>
    </div>
  </div>
</template>

<script>
import { request } from "@/api/service";
export default {
  data() {
    return {
      pageData: {
        interval: null
      },
      orderNo: "",
      orderId: null
    };
  },
  created() {
    const { orderNo, orderId } = this.$route.query;
    this.orderNo = orderNo;
    this.orderId = orderId;
    this.initInterval();
  },
  methods: {
    initInterval() {
      this.pageData.interval = setInterval(this.calcInterval, 1000 * 3);
    },
    calcInterval() {
      this.clearInterval();
      this.toContractList();
    },
    clearInterval() {
      this.pageData.interval && clearInterval(this.pageData.interval);
    },
    async toContractList() {
      const res = await request({
        url: `/afis-carservice/contract/order/load/contract/${this.orderId}`,
        method: "GET"
      });
      this.$router.replace({
        path: "/product/contract/list",
        query: {
          name: res && res.contractNo
        }
      });
    },
    async toContractDetail() {
      const res = await request({
        url: `/afis-carservice/contract/order/load/contract/${this.orderId}`,
        method: "GET"
      });
      if (!(res && res.id)) {
        this.$toast("未查询到合同信息");
        return false;
      }
      this.$router.replace({
        path: "/product/contract/detailPdf",
        query: {
          id: res.id
        }
      });
    }
  },
  beforeDestroy() {
    this.clearInterval();
  }
};
</script>

<style lang="scss" scoped>
.btn {
  margin-top: 30px;
  padding: 0 10px;
}
</style>
